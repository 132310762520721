import React from "react"
import Header from "../components/header";
import {PageContainer, Container} from "../components/container";
import "./index.scss";
import SEO from "../components/seo";

const NotFoundPage = () => {


  return (
    <PageContainer>
      <div role="presentation">
      <Container>
        <div className="stripe stripe-1"></div>
        <div className="stripe stripe-2"></div>
        <div className="large-bg"></div>
        </Container>
      </div>
        
      <div id="homePage" className="homePage">

        <SEO title="MyShoots - Page Not Found" />
        <Header />
        <Container>

          <div className="banner">
            <div className="banner-left">
              <div className="page-title">
                <p className="banner-subtitle">
                  404
                </p>
                <h1 className="banner-title">
                  Page Not Found
                </h1>
                <p className="banner-subtitle">
                  You visited a page that does not exist.
                </p>
              </div>


              {/* <SubscriptionForm /> */}
  
            </div>

          </div>
        </Container>

      </div>


    </PageContainer>
  )
}

export default NotFoundPage
